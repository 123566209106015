<template>
  <VueFinalModal
    v-model="showModal"
    :esc-to-close="true"
    @closed="closed"
    class="modal-container"
    content-class="modal-content3"
  >
    <button
      v-if="!isPdf"
      class="p-1 btn btn-link text-dark fixed-plugin-close-button"
      style="
        background-color: #333333;
        border-radius: 16px;
        border: solid 3px #dcdcdc;
        width: 32px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        margin: 5px 0 10px 0;
        align-self: self-end;
        padding: 6px 10px !important;
        position: absolute;
        top: 12px;
        right: 12px;
        z-index: 10;
      "
      @click="closed"
    >
      <i class="fa fa-close" style="color: #dcdcdc"></i>
    </button>
    <button
      v-if="isPdf"
      class="p-1 btn btn-link text-dark fixed-plugin-close-button"
      style="
        background-color: #333333;
        border-radius: 16px;
        border: solid 3px #dcdcdc;
        width: 32px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        margin: 5px 0 10px 0;
        align-self: self-end;
        padding: 6px 10px !important;
        position: absolute;
        bottom: 12px;
        right: 12px;
      "
      @click="closed"
    >
      <i class="fa fa-close" style="color: #dcdcdc"></i>
    </button>
    <!-- <iframe
      v-if="this.activeType !== 'plantillas'"
      :src="this.srcUrl"
      height="100%"
      width="100%"
    ></iframe>
    <img
      v-else
      :src="this.srcUrl"
      style="height: 100%; width: 100%; padding: 20px; object-fit: contain"
    /> -->
    <iframe v-if="isPdf" :src="srcUrl" height="100%" width="100%"></iframe>
    <div
      v-else-if="isImage"
      style="
        display: flex;
        flex-direction: column;
        gap: 12px;
        padding: 20px;
        height: 100%;
        width: 100%;
      "
    >
      <img
        :src="srcUrl"
        style="height: calc(100% - 41px); width: 100%; object-fit: contain"
      />
      <button
        @click="downloadResource(srcUrl, resourceName)"
        class="btn btn-primary"
      >
        Descargar
      </button>
    </div>
    <video
      v-else-if="isVideo"
      controls
      style="height: 100%; width: 100%; object-fit: contain"
    >
      <source :src="srcUrl" type="video/mp4" />
      Your browser does not support the video tag.
    </video>
    <div v-else>Unsupported file type.</div>
  </VueFinalModal>
  <div>
    <div class="foldersContainer">
      <div class="buttonsContainer">
        <button
          class="folder"
          :class="{ activeBtn: activeType === 'documentos' }"
          @click="loadResources('documentos')"
        >
          <i
            class="fa-solid fa-file text-success"
            :class="{ activeBtn: activeType === 'documentos' }"
          ></i>
          <p class="text" style="margin: 0">Documentos</p>
        </button>
        <button
          class="folder"
          :class="{ activeBtn: activeType === 'plantillas' }"
          @click="loadResources('plantillas')"
        >
          <i
            class="fa-solid fa-image text-success"
            :class="{ activeBtn: activeType === 'plantillas' }"
          ></i>
          <p class="text" style="margin: 0">Plantillas</p>
        </button>
        <button
          class="folder"
          :class="{ activeBtn: activeType === 'videos' }"
          @click="loadResources('videos')"
        >
          <i
            class="fa-solid fa-video text-success"
            :class="{ activeBtn: activeType === 'videos' }"
          ></i>
          <p class="text" style="margin: 0">Videos</p>
        </button>
      </div>
      <hr />
      <div
        v-if="activeType === 'plantillas' && !loading"
        class="archivesContainer"
      >
        <div v-if="pngs.length === 0">No hay Plantillas Disponibles</div>
        <div
          v-else
          v-for="png in pngs"
          :key="png.name"
          @click="clickedResource(png)"
        >
          <!-- <button @click="downloadResource(png)" class="archive"> -->
          <button class="archive">
            <i class="fa-solid fa-image text-success"></i>
            <p style="font-size: 14px; margin: 0">{{ png.name }}</p>
          </button>
        </div>
      </div>
      <div
        v-if="activeType === 'documentos' && !loading"
        class="archivesContainer"
      >
        <div v-if="pdfs.length === 0">No hay Documentos Disponibles</div>
        <div
          v-else
          v-for="pdf in pdfs"
          :key="pdf.name"
          @click="clickedResource(pdf)"
        >
          <!-- <button @click="downloadResource(pdf)" class="archive"> -->
          <button class="archive">
            <i class="fa-solid fa-file text-success"></i>
            <p style="font-size: 14px; margin: 0">{{ pdf.name }}</p>
          </button>
        </div>
      </div>
      <div v-if="activeType === 'videos' && !loading" class="archivesContainer">
        <div v-if="videos.length === 0">No hay Videos Disponibles</div>
        <div
          v-else
          v-for="video in videos"
          :key="video.name"
          @click="clickedResource(video)"
        >
          <!-- <button @click="downloadResource(video)" class="archive"> -->
          <button class="archive">
            <i class="fa-solid fa-video text-success"></i>
            <p style="font-size: 14px; margin: 0">{{ video.name }}</p>
          </button>
        </div>
      </div>

      <div v-if="loading" class="archivesContainer">
        <div>
          <Skeletor
            :shimmer="true"
            class="skeletorClass"
            style="border-radius: 10px"
          />
        </div>
        <div>
          <Skeletor
            :shimmer="true"
            class="skeletorClass"
            style="border-radius: 10px"
          />
        </div>
        <div>
          <Skeletor
            :shimmer="true"
            class="skeletorClass"
            style="border-radius: 10px"
          />
        </div>
      </div>
      <!-- <div v-if="activeType === 'documentos'" class="archivesContainer">
        <div v-for="pdf in pdfs" :key="pdf.name">
          <button @click="downloadResource(pdf)" class="archive">
            <i class="fa-solid fa-file text-success"></i>
            <p style="font-size: 14px">{{ pdf.name }}</p>
          </button>
        </div>
      </div>
      <div v-if="activeType === 'videos'" class="archivesContainer">
        <div v-for="video in videos" :key="video.name">
          <button @click="downloadResource(video)" class="archive">
            <i class="fa-solid fa-video text-success"></i>
            <p style="font-size: 14px">{{ video.name }}</p>
          </button>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
/*eslint-disable */

import "firebase/compat/storage";
import { VueFinalModal } from "vue-final-modal";
import "vue-final-modal/style.css";

export default {
  name: "recursos",
  components: {
    VueFinalModal,
  },
  data() {
    return {
      activeType: "plantillas",
      pngs: [],
      pdfs: [],
      videos: [],
      loading: true,
      srcUrl: null,
      showModal: false,
      extension: null,
      resourceName: null,
    };
  },
  activated() {
    if (this.isMobile) {
      this.$eventBus.emit("activeShowMenu");
    }
  },

  computed: {
    fileExtension() {
      return this.srcUrl ? this.srcUrl.split(".").pop().toLowerCase() : "";
    },
    isPdf() {
      return this.extension === "pdf";
    },
    isImage() {
      return ["png", "jpg", "jpeg", "gif"].includes(this.extension);
    },
    isVideo() {
      return this.extension === "mp4";
    },
  },
  methods: {
    closed() {
      // this.$eventBus.emit("reiniciarFormVentaDashboard");
      this.showModal = false;
      this.srcUrl = null;
    },

    async loadResources(type) {
      this.loading = true;
      this.activeType = type;
      const storageRef = this.$appFirebase.storage().ref().child(type);
      const listResult = await storageRef.listAll();
      if (type === "plantillas") {
        console.log("listResult", listResult);
        // this.pngs = listResult.items;
        this.pngs = await Promise.all(
          listResult.items.map(async (item) => {
            console.log("El item", item);
            return {
              name: item.name,
              url: await item.getDownloadURL(),
              ext: item.name.split(".")[1],
            };
          })
        );
      } else if (type === "documentos") {
        // this.pdfs = listResult.items;
        this.pdfs = await Promise.all(
          listResult.items.map(async (item) => {
            return {
              name: item.name,
              url: await item.getDownloadURL(),
              ext: item.name.split(".")[1],
            };
          })
        );
      } else if (type === "videos") {
        // this.videos = listResult.items;
        this.videos = await Promise.all(
          listResult.items.map(async (item) => {
            return {
              name: item.name,
              url: await item.getDownloadURL(),
              ext: item.name.split(".")[1],
            };
          })
        );
      }
      this.loading = false;
    },
    async downloadResource(resource, resourceName) {
      const response = await fetch(resource.url);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      console.log("el resource", resource);
      a.download = resourceName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    },
    async clickedResource(resource) {
      this.extension = resource.ext;
      this.srcUrl = resource.url;
      this.resourceName = resource.name;
      this.showModal = true;
    },
  },
  mounted() {
    this.loadResources("documentos");
  },
};
</script>

<style scoped>
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.no-spinner::-webkit-inner-spin-button,
.no-spinner::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.vs__search {
  height: 30px;
}

/* .foldersContainer {
  display: flex;
  background-color: white;
  justify-content: flex-start;
  border-radius: 20px;
  padding: 20px;
  flex-direction: column;
}

.folder {
  padding: 10px;
  border-radius: 10px;
  min-width: 120px;
  background-color: white;
  box-shadow: rgb(0 0 0 / 10%) 0px 2px 8px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
}

.active {
  background-color: #7d0c7e; 
  color: white;
  .text {
    color: white;
  }

  i {
    color: white !important;
  }
}

.text {
  color: black;
  font-weight: 600;
}

.folderInfo {
  margin-top: 20px;
  padding: 10px;
  background-color: #f0f0f0;
}

.buttonsContainer {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.archive {
  box-shadow: rgb(0 0 0 / 10%) 0px 2px 8px;
  padding: 10px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  color: #333333;
  gap: 20px;
}

.archivesContainer {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  padding: 20px;
}

.skeletorClass {
  width: 200px;
  height: 44px;
}

.archive:hover {
  background-color: #f4f4f4;
} */
</style>
