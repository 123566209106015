<template>
  <div>
    <VueFinalModal
      v-model="showModal"
      :esc-to-close="true"
      @closed="closed"
      class="modal-container"
      content-class="modal-content2"
    >
      <div class="modal__content">
        <div class="row">
          <div class="col-11 col-sm-11 col-md-11 col-xl-11 col-lg-11"></div>

          <div
            class="col-1 col-sm-1 col-md-1 col-xl-1 col-lg-1"
            @click="closed"
          >
            <i class="fas fa-times-square close_modal"></i>
          </div>
        </div>

        <form-venta
          v-if="showModal"
          :key="componentKeyFormVenta"
          :promo-code="promoCode"
          :token-auth="tokenAuth"
        />
      </div>
    </VueFinalModal>

    <VueFinalModal
      v-model="showModal2"
      :esc-to-close="true"
      @closed="closed2"
      class="modal-container"
      content-class="modal-content"
      header-class="modal-header"
    >
      <div class="modal__content">
        <div class="row">
          <div class="col-11 col-sm-11 col-md-11 col-xl-11 col-lg-11"></div>

          <div
            class="col-1 col-sm-1 col-md-1 col-xl-1 col-lg-1"
            @click="closed2"
          >
            <i class="fas fa-times-square close_modal"></i>
          </div>
        </div>

        <retiro-cash
          v-if="showModal2"
          :key="componentKeyFormRetiro"
          :user-id="userId"
          :token-auth="tokenAuth"
          :close-method="closed2"
          v-model:available-amount="balance"
        />
      </div>
    </VueFinalModal>

    <div class="row">
      <div class="col-12 col-sm-12 col-lg-12 col-md-12 col-xl-12">
        <swiper
          :slides-per-view="4"
          :space-between="15"
          :centered-slides="false"
          :navigation="false"
          :pagination="false"
          :breakpoints="{
            200: {
              slidesPerView: 1.1,
            },
            400: {
              slidesPerView: 1.1,
            },
            550: {
              slidesPerView: 2.5,
            },
            768: {
              slidesPerView: 3.5,
            },
            1024: {
              slidesPerView: 4,
            },
            1200: {
              slidesPerView: 6,
            },
          }"
        >
          <swiper-slide>
            <saldo
              :key="componentKeySaldos"
              :user-id="userId"
              :token-auth="tokenAuth"
              v-model:available-amount="availableAmount"
              v-model:loading="loading"
              :refreshTeam="refreshTeam"
              v-model:balance="balance"
            />
          </swiper-slide>
          <swiper-slide>
            <mis-ventas-directas
              :key="componentKeyMisVentas"
              :user-id="userId"
              :token-auth="tokenAuth"
              v-model:my-sales="mySales"
              v-model:loading="loading"
              v-model:active-sales-quantity="activeSalesQuantity"
              :refreshTeam="refreshTeam"
            />
          </swiper-slide>
          <swiper-slide>
            <referidos-primer-nivel
              :key="componentKeyPrimerNivel"
              :user-id="userId"
              :token-auth="tokenAuth"
              v-model:loading="loading"
              v-model:level-one-team="levelOneTeam"
              :refreshTeam="refreshTeam"
              v-model:level-one-amount="levelOneAmount"
              v-model:sales-quantity="salesQuantity"
            />
          </swiper-slide>
          <swiper-slide>
            <referidos-segundo-nivel
              :key="componentKeySegundoNivel"
              :user-id="userId"
              :token-auth="tokenAuth"
              v-model:loading="loading"
              :refreshTeam="this.refreshTeam"
              v-model:level-two-team="levelTwoTeam"
              v-model:level-two-amount="levelTwoAmount"
            />
          </swiper-slide>
          <swiper-slide>
            <referidos-tercer-nivel
              :key="componentKeyTercerNivel"
              :user-id="userId"
              :token-auth="tokenAuth"
              v-model:loading="loading"
              :refreshTeam="this.refreshTeam"
              v-model:level-three-team="levelThreeTeam"
              v-model:level-three-amount="levelThreeAmount"
            />
          </swiper-slide>
          <swiper-slide>
            <referidos-cuarto-nivel
              :key="componentKeyCuartoNivel"
              :user-id="userId"
              :token-auth="tokenAuth"
              v-model:loading="loading"
              :refreshTeam="this.refreshTeam"
              v-model:level-four-team="levelFourTeam"
              v-model:level-four-amount="levelFourAmount"
            />
          </swiper-slide>
        </swiper>
      </div>

      <div
        class="col-12 col-sm-12 col-lg-12 col-md-12 col-xl-12"
        v-if="!isMobile"
      >
        <div class="row">
          <div
            class="col-12 col-sm-12 col-lg-4 col-md-12 col-xl-4"
            v-if="!isMobile"
          >
            <div
              class="dashboardActionsContainer"
              style="margin-bottom: 10px; width: auto"
            >
              <div class="col-12 col-sm-12 col-lg-12 col-md-6 col-xl-12">
                <div class="card h-100 sellCard" style="margin-bottom: 10px">
                  <div class="card-body">
                    <h6
                      style="
                        font-size: 14px;
                        font-weight: bold;
                        margin-bottom: 20px;
                        line-height: 15px;
                        text-align: center;
                      "
                      class="sellCardFont"
                    >
                      ¡Vende el Internet de Xtrim!
                    </h6>
                    <div align="center">
                      <!-- <a
                        href="https://distribuidores.xtrim.com.ec/compra-en-linea/login"
                        target="_blank"
                        class="btn btn-primary btn-lg btn-venta"
                        style="color: white !important; padding: 15px 25px"
                      >
                        Vender Xtrim
                      </a> ESTE ES EL BOTON QUE LLEVA AL ECOMMERCE DE XTRIM SE COMENTA PARA HACER EL MODAL CON EL FOMRULARIO -->

                      <button
                        class="btn btn-primary btn-lg btn-venta"
                        style="color: white !important; padding: 15px 25px"
                        @click="registerReferido"
                      >
                        Vender Xtrim
                      </button>

                      <hr class="horizontal dark" style="margin-bottom: 10px" />

                      <a
                        href="https://www.youtube.com/watch?v=nnggM9UBwtw&ab_channel=XtrimPro"
                        target="_blank"
                        ><p class="a_link">Ver tutorial de uso</p></a
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="col-12 col-sm-12 col-lg-12 col-md-6 col-xl-12 mb-3 mb-lg-0"
              >
                <link-referido :promo-code="promoCode" :is-mobile="isMobile" />
              </div>
            </div>
          </div>
          <div
            class="col-12 col-sm-12 col-lg-8 col-md-12 col-xl-8"
            style="margin-top: 0px; overflow: auto"
          >
            <listado-ventas
              :key="componentKeyListadoVentas"
              :user-id="userId"
              :token-auth="tokenAuth"
            />
          </div>
        </div>
      </div>

      <!-- <div class="col-12 col-sm-12 col-lg-6 col-md-6 col-xl-12">
        <link-venta :promo-code="promoCode" :is-mobile="isMobile" />
      </div> -->

      <div
        class="col-12 col-sm-12 col-lg-12 col-md-12 col-xl-12"
        v-else
        style="margin-bottom: 10px"
      >
        <div class="row">
          <!-- <swiper
          :slides-per-view="3"
          :space-between="15"
          :centered-slides="false"
          :navigation="false"
          :pagination="false"
          :breakpoints="{
            200: {
              slidesPerView: 1.1,
            },
            400: {
              slidesPerView: 1.1,
            },
            768: {
              slidesPerView: 1.1,
            },
            1024: {
              slidesPerView: 1.1,
            },
            1200: {
              slidesPerView: 1.1,
            },
          }"
        >
          <swiper-slide>
            </swiper-slide>
            <swiper-slide>
              <link-venta :promo-code="promoCode" :is-mobile="isMobile" />
            </swiper-slide>
          </swiper> -->
          <div class="column col-12 col-sm-12 col-lg-4 col-md-12 col-xl-4">
            <div
              class="card sellCard"
              style="margin-bottom: 10px; margin-top: 10px"
            >
              <div class="card-body">
                <h6
                  style="
                    font-size: 14px;
                    font-weight: bold;
                    margin-bottom: 20px;
                    line-height: 15px;
                    text-align: center;
                  "
                  class="sellCardFont"
                >
                  ¡Vende el Internet de Xtrim!
                </h6>
                <div align="center">
                  <!-- <button
                @click="registerReferido"
                class="btn btn-primary btn-lg"
                style="
                  background-color: #f3cc23 !important;
                  color: #7d0c7e !important;
                  padding: 15px 25px;
                "
              >
                Vender Xtrim
              </button> -->
                  <!-- <a
                    href="https://distribuidores.xtrim.com.ec/compra-en-linea/login"
                    target="_blank"
                    class="btn btn-primary btn-lg btn-venta"
                    style="padding: 15px 25px"
                  >
                    Vender Xtrim
                  </a> -->

                  <button
                    class="btn btn-primary btn-lg btn-venta"
                    style="color: white !important; padding: 15px 25px"
                    @click="registerReferido"
                  >
                    Vender Xtrim
                  </button>
                  <hr class="horizontal dark" style="margin-bottom: 10px" />

                  <a
                    href="https://www.youtube.com/watch?v=nnggM9UBwtw&ab_channel=XtrimPro"
                    target="_blank"
                    ><p class="a_link sellCardFont" style="color: #722079">
                      Ver tutorial de uso
                    </p></a
                  >
                </div>
              </div>
            </div>
            <link-referido :promo-code="promoCode" :is-mobile="isMobile" />
          </div>
          <div
            class="col-12 col-sm-12 col-lg-8 col-md-12 col-xl-8"
            style="margin-top: 10px; overflow: auto"
          >
            <listado-ventas
              :key="componentKeyListadoVentas"
              :user-id="userId"
              :token-auth="tokenAuth"
            />
          </div>
        </div>
      </div>

      <div
        class="float_whatsapp d-none d-sm-none d-md-inline d-lg-inline d-xl-inline col-12 col-sm-12 col-lg-12 col-md-12 col-xl-12"
      >
        <a :href="textAyuda" target="_blank">
          <div style="justify-content: space-between; display: flex">
            <svg
              width="30"
              class="my-float"
              style="margin-top: 7px; margin-left: 10px"
              height="30"
              viewBox="0 0 16 16"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentcolor"
            >
              <path
                d="M13.601 2.326A7.854 7.854.0 007.994.0C3.627.0.068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933.0 003.79.965h.004c4.368.0 7.926-3.558 7.93-7.93A7.898 7.898.0 0013.6 2.326zM7.994 14.521a6.573 6.573.0 01-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251A6.56 6.56.0 011.407 7.922c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56.0 014.66 1.931 6.557 6.557.0 011.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729.0 00-.529.247c-.182.198-.691.677-.691 1.654.0.977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z"
              />
            </svg>

            <p class="dudas_txt">¿Dudas o problemas? Contácte con soporte</p>
          </div>
        </a>
      </div>

      <div
        class="d-inline d-sm-inline d-md-none d-lg-none d-xl-none col-12 col-sm-12 col-lg-12 col-md-12 col-xl-12"
      >
        <a class="float" :href="textAyuda" target="_blank">
          <svg
            width="30"
            class="my-float"
            style="margin-top: 14px"
            height="30"
            viewBox="0 0 16 16"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentcolor"
          >
            <path
              d="M13.601 2.326A7.854 7.854.0 007.994.0C3.627.0.068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933.0 003.79.965h.004c4.368.0 7.926-3.558 7.93-7.93A7.898 7.898.0 0013.6 2.326zM7.994 14.521a6.573 6.573.0 01-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251A6.56 6.56.0 011.407 7.922c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56.0 014.66 1.931 6.557 6.557.0 011.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729.0 00-.529.247c-.182.198-.691.677-.691 1.654.0.977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z"
            />
          </svg>
        </a>
      </div>
    </div>
  </div>
</template>
<script>
/*eslint-disable */
import Saldo from "./Saldo.vue";
import LinkReferido from "./LinkReferido.vue";
import LinkVenta from "./LinkVenta.vue";
import ReferidosPrimerNivel from "./ReferidosPrimerNivel.vue";
import ReferidosSegundoNivel from "./ReferidosSegundoNivel.vue";
import ReferidosTercerNivel from "./ReferidosTercerNivel.vue";
import ReferidosCuartoNivel from "./ReferidosCuartoNivel.vue";
import RegistroReferido from "./RegistroReferido.vue";
import MisVentasDirectas from "./MisVentasDirectas.vue";
import ListadoVentas from "./ListadoVentas.vue";
import FormVenta from "../FormVenta";
import RetiroCash from "./RetiroCash.vue";
import { VueFinalModal } from "vue-final-modal";
import "vue-final-modal/style.css";

import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
export default {
  name: "dashboard-default",
  data() {
    let datos = JSON.parse(this.$store.state.user);

    return {
      showModal: false,
      showModal2: false,
      isMobile: this.$isMobile,
      // isMobile: true,
      loading: false,
      componentKeySaldos: 0,
      componentKeyPrimerNivel: 100,
      componentKeySegundoNivel: 200,
      componentKeyTercerNivel: 300,
      componentKeyCuartoNivel: 400,
      componentKeyListadoVentas: 500,
      componentKeyFormVenta: 600,
      componentKeyFormRetiro: 700,
      componentKeyMisVentas: 800,
      promoCode: datos.promoCode,
      userId: datos.userId,
      displayName: datos.displayName,
      tokenAuth: datos.tokenAuth,
      levelOneTeam: 0,
      levelTwoTeam: 0,
      levelThreeTeam: 0,
      levelFourTeam: 0,
      levelOneAmount: 0,
      levelTwoAmount: 0,
      levelThreeAmount: 0,
      levelFourAmount: 0,
      mySales: 0,
      activeSalesQuantity: 0,
      balance: 0,

      link:
        "Hola soy " +
        datos.displayName +
        " y te invito a formar parte de XTRIMPRO y ganar dinero refiriendo el internet de XTRIM, Registrate en el siguiente link: " +
        window.location.host +
        "/invitacion/" +
        datos.promoCode,
      textAyuda:
        "https://api.whatsapp.com/send?phone=593980752500&text=Hola,%20soy%20" +
        datos.displayName +
        "%20y%20deseo%20obtener%20ayuda%20en%20XTRIMPRO",
    };
  },
  components: {
    Saldo,
    VueFinalModal,
    FormVenta,
    LinkReferido,
    LinkVenta,
    RegistroReferido,
    ReferidosPrimerNivel,
    ReferidosSegundoNivel,
    ReferidosTercerNivel,
    ReferidosCuartoNivel,
    MisVentasDirectas,
    ListadoVentas,
    RetiroCash,
    Swiper,
    SwiperSlide,
  },
  computed: {
    availableAmount() {
      const available = this.activeSalesQuantity >= 5;
      if (available) {
        return (
          this.levelOneAmount +
          this.levelTwoAmount +
          this.levelThreeAmount +
          this.levelFourAmount +
          this.mySales
        );
      } else {
        return this.mySales;
      }
    },
  },
  methods: {
    registerReferido() {
      this.$eventBus.emit("showModalVenta");
    },
    copyText() {
      if (this.isMobile) {
        if (navigator.share) {
          navigator
            .share({
              title: "XTRIMPRO - Link de referidos de" + this.displayName,
              text:
                "Hola soy " +
                this.displayName +
                " y te invito a formar parte de XTRIMPRO y ganar dinero refiriendo el internet de XTRIM, Registrate en el siguiente link: ",
              url: "invitacion/" + this.promoCode,
            })
            .then(() => console.log("Successful share"))
            .catch((error) => console.log("Error sharing", error));
        }
      } else {
        // Copy the text inside the text field
        navigator.clipboard.writeText(this.link);

        this.$toast.success("Link Copiado exitosamente", {
          position: "top-right",
          max: 10,
        });
      }
    },
    closed() {
      this.$eventBus.emit("reiniciarFormVentaDashboard");
      this.showModal = false;
    },

    closed2() {
      this.$eventBus.emit("ReiniciarRetiroCash");
      this.showModal2 = false;
    },
    copyInputValue(model) {
      const inputElement = document.getElementById(model);
      inputElement.select();
      document.execCommand("copy");
      inputElement.setSelectionRange(0, 0);
      this.$toast.success("Link Copiado exitosamente", {
        position: "top-right",
        max: 10,
      });
    },
    getTeamInfo() {
      this.loading = true;
      this.$https
        .post("/user/getCantReferidos", {
          userId: this.userId,
          tokenAuth: this.tokenAuth,
        })
        .then((response) => {
          console.log(response.data);
          this.levelOneTeam = response.data.cant_nivelUnoUser;
          this.levelTwoTeam = response.data.cant_nivelDosUser;
          this.levelThreeTeam = response.data.cant_nivelTresUser;
          this.levelFourTeam = response.data.cant_nivelCuatroUser;
          this.levelOneAmount = response.data.cant_V1;
          this.levelTwoAmount = response.data.cant_V2;
          this.levelThreeAmount = response.data.cant_V3;
          this.levelFourAmount = response.data.cant_V4;
          this.mySales = response.data.cant_vDirectas;
          this.activeSalesQuantity = response.data.cant_ventasDirectas;
          this.balance = response.data.balanceData;

          this.loading = false;
        })
        .catch((error) => {
          if (error.response.data.code !== 500) {
            this.$toast.error("Ocurrió un error inesperado", {
              position: "top-right",
              max: 10,
            });
          }
          this.loading = false;
        });
    },
    refreshTeam() {
      this.getTeamInfo();
    },
  },
  created() {},
  beforeUnmount() {},
  activated() {
    if (this.isMobile) {
      this.$eventBus.emit("activeShowMenu");
    }
  },
  deactivated() {
    console.log("About has been deactivated");
  },

  mounted() {
    this.getTeamInfo();

    this.$eventBus.on("reiniciarFormVentaDashboard", () => {
      this.componentKeyFormVenta += 1;
    });

    this.$eventBus.on("ReiniciarRetiroCash", () => {
      this.componentKeyFormRetiro += 1;
    });

    this.$eventBus.on("closeModalVenta", () => {
      this.showModal = false;
    });

    this.$eventBus.on("showModalVenta", () => {
      this.showModal = true;
    });

    //Retirar Cash
    this.$eventBus.on("closeModalRetiro", () => {
      this.showModal2 = false;
    });

    this.$eventBus.on("showModalRetiro", () => {
      this.showModal2 = true;
    });

    document.title = "XTrimPro - Inicio";

    this.$eventBus.on("reiniciarListadoVentas", () => {
      this.componentKeyListadoVentas += 1;
    });

    this.$eventBus.on("reiniciarSaldos", () => {
      this.componentKeySaldos += 1;
    });

    this.$eventBus.on("reiniciarPrimerNivel", () => {
      this.componentKeyPrimerNivel += 1;
    });

    this.$eventBus.on("reiniciarSegundoNivel", () => {
      this.componentKeySegundoNivel += 1;
    });
    this.$eventBus.on("reiniciarTercerNivel", () => {
      this.componentKeyTercerNivel += 1;
    });
    this.$eventBus.on("reiniciarCuartoNivel", () => {
      this.componentKeyCuartoNivel += 1;
    });
  },
};
</script>

<style></style>
