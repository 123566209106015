<template>
  <div
    style="
      display: flex;
      flex-direction: column;
      border-radius: 1rem;
      background-color: white;
      justify-content: space-between;
      padding: 16px;
    "
  >
    <div style="display: flex; gap: 20px; justify-content: space-between">
      <div style="display: flex; gap: 20px; align-items: center">
        <div style="font-weight: 600; font-size: 18px">
          {{ this.sale.sales }}
        </div>
        <div>{{ this.sale.refers }}</div>
      </div>
      <button
        class="btn btn-primary"
        :id="'detailBtn-' + this.sale.userId"
        :disabled="loading"
        type="button"
        @click="toggleDetails"
      >
        <span v-if="loading">
          <i class="fas fa-spinner fa-pulse"></i> Cargando...
        </span>
        <span v-else>
          {{ buttonText }}
        </span>
      </button>
    </div>
    <div
      v-if="showDetails"
      style="margin-top: 12px; display: flex; flex-direction: column"
    >
      <div style="height: 1px; width: 100%; border: 0.5px solid #f2f2f2" />
      <p
        style="
          color: #67748e;
          font-size: 16px;
          font-weight: 600;
          margin-top: 8px;
        "
      >
        Detalle Promotor
      </p>
      <p style="color: #67748e; margin: 0 0 0 6px">
        <span style="font-weight: 600">Fecha de Registro: </span>
        {{ userData.dateRegister }}
      </p>
      <p style="color: #67748e; margin: 4px 0 0 6px">
        <span style="font-weight: 600">Identificación:</span> {{ userData.dni }}
      </p>
      <p style="color: #67748e; margin: 4px 0 0 6px">
        <span style="font-weight: 600">Teléfono:</span> {{ userData.phone }}
      </p>
      <p style="color: #67748e; margin: 4px 0 0 6px">
        <span style="font-weight: 600">Correo:</span> {{ userData.email }}
      </p>
      <div
        style="color: #67748e; margin: 4px 0 0 6px"
        v-if="userData.userIdFourthLevel"
      >
        <p style="color: #67748e; margin: 4px 0 0 6px; font-weight: 600">
          - Líder 4
        </p>
        <p style="color: #67748e; margin: 4px 0 0 20px">
          <span style="font-weight: 600">Nombre:</span>
          {{ userData.userIdFourthLevel.displayName }}
        </p>
        <p style="color: #67748e; margin: 4px 0 0 20px">
          <span style="font-weight: 600">Teléfono:</span>
          {{ userData.userIdFourthLevel.phone }}
        </p>
        <p style="color: #67748e; margin: 4px 0 0 20px">
          <span style="font-weight: 600">Correo:</span>
          {{ userData.userIdFourthLevel.email }}
        </p>
      </div>
      <div
        style="color: #67748e; margin: 4px 0 0 6px"
        v-if="userData.userIdThirdLevel"
      >
        <p style="color: #67748e; margin: 4px 0 0 6px; font-weight: 600">
          - Líder 3
        </p>
        <p style="color: #67748e; margin: 4px 0 0 20px">
          <span style="font-weight: 600">Nombre:</span>
          {{ userData.userIdThirdLevel.displayName }}
        </p>
        <p style="color: #67748e; margin: 4px 0 0 20px">
          <span style="font-weight: 600">Teléfono:</span>
          {{ userData.userIdThirdLevel.phone }}
        </p>
        <p style="color: #67748e; margin: 4px 0 0 20px">
          <span style="font-weight: 600">Correo:</span>
          {{ userData.userIdThirdLevel.email }}
        </p>
      </div>
      <div
        style="color: #67748e; margin: 4px 0 0 6px"
        v-if="userData.userIdSecondLevel"
      >
        <p style="color: #67748e; margin: 4px 0 0 6px; font-weight: 600">
          - Líder 2
        </p>
        <p style="color: #67748e; margin: 4px 0 0 20px">
          <span style="font-weight: 600">Nombre:</span>
          {{ userData.userIdSecondLevel.displayName }}
        </p>
        <p style="color: #67748e; margin: 4px 0 0 20px">
          <span style="font-weight: 600">Teléfono:</span>
          {{ userData.userIdSecondLevel.phone }}
        </p>
        <p style="color: #67748e; margin: 4px 0 0 20px">
          <span style="font-weight: 600">Correo:</span>
          {{ userData.userIdSecondLevel.email }}
        </p>
      </div>
      <div
        style="color: #67748e; margin: 4px 0 0 6px"
        v-if="userData.userIdFirstLevel"
      >
        <p style="color: #67748e; margin: 4px 0 0 6px; font-weight: 600">
          - Líder 1
        </p>
        <p style="color: #67748e; margin: 4px 0 0 20px">
          <span style="font-weight: 600">Nombre:</span>
          {{ userData.userIdFirstLevel.displayName }}
        </p>
        <p style="color: #67748e; margin: 4px 0 0 20px">
          <span style="font-weight: 600">Teléfono:</span>
          {{ userData.userIdFirstLevel.phone }}
        </p>
        <p style="color: #67748e; margin: 4px 0 0 20px">
          <span style="font-weight: 600">Correo:</span>
          {{ userData.userIdFirstLevel.email }}
        </p>
      </div>
      <div style="margin-top: 12px; display: flex; flex-direction: column">
        <div style="height: 1px; width: 100%; border: 0.5px solid #f2f2f2" />
        <p
          style="
            color: #67748e;
            font-size: 16px;
            font-weight: 600;
            margin: 8px 0;
          "
        >
          Planes Vendidos: {{ this.sale.sales }}
        </p>
        <p
          style="color: #67748e; margin: 4px 0 0 6px"
          v-for="(plan, index) in sale.plansNames"
          :key="index"
        >
          - {{ plan }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["sale"],
  data() {
    return {
      userData: null,
      loading: false,
      showDetails: false,
    };
  },
  computed: {
    buttonText() {
      return this.showDetails ? "Ver Menos" : "Ver Detalle";
    },
  },
  watch: {},
  mounted() {
    console.log(this.sale);
  },
  methods: {
    toggleDetails() {
      if (this.userData) {
        // If userData is already loaded, simply toggle the visibility
        this.showDetails = !this.showDetails;
      } else {
        // If userData is not loaded, fetch the data from the backend
        this.getDetails();
      }
    },
    getDetails() {
      this.loading = true;

      this.$https
        .post("/user/getUserIdDetalle", {
          userId: this.sale.userId,
        })
        .then((response) => {
          console.log(response.data.datos[0]);
          this.loading = false;
          this.userData = response.data.datos[0];
          this.showDetails = true;
        })
        .catch((error) => {
          console.error("Error:", error);
          this.$toast.error("Ocurrió un error", {
            position: "top-right",
            max: 10,
          });
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss">
@import "datatables.net-dt";
@import "datatables.net-responsive-dt";

.container {
  display: flex;
  gap: 12px;
  flex-direction: column;
}

.stat {
  padding: 15px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  color: #333333;
  .title {
    align-self: self-start;
    font-size: 18px;
    font-weight: 600;
  }
  .number {
    align-self: self-end;
    font-size: 28px;
    font-weight: 800;
  }
}

@media (max-width: 550px) {
  .stat {
    width: 100%;
  }

  .date-input {
    width: 100%;
  }
}

.dateSpan {
  display: flex;
  flex-direction: column;
}

.dateSpanText {
  font-size: 12px;
  font-weight: 500;
  color: #7c7c7c;
}

.historicalDate {
  width: 100%;
  display: flex;
  gap: 20px;
  justify-content: space-between;
}

.statContainer {
  display: flex;
  gap: 12px;
  //   flex-wrap: wrap;
}

@media (max-width: 1200px) {
  .statContainer {
    flex-direction: column;
  }
}

.dateContainer {
  display: flex;
  gap: 12px;
  align-self: flex-start;
}

.labelText {
  font-size: 14px;
  margin: 0;
}

.date-input {
  display: flex;
  align-items: center;
  flex-direction: column;
  align-items: start;
}

.nextStats {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
</style>
