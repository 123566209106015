<template>
  <div>
    <div class="row">
      <div class="col-12 col-sm-12 col-md-2 col-lg-3 col-xl-3"></div>
      <div class="col-12 col-sm-12 col-md-8 col-lg-6 col-xl-6">
        <div class="card">
          <div class="card-header pb-0">
            <p class="mb-0" style="text-align: center">INFORMACIÓN BANCARIA</p>

            <hr class="horizontal dark" />
          </div>
          <form role="form" autocomplete="off" @submit.prevent="onSubmitCuenta">
            <div class="card-body">
              <div class="row">
                <div
                  class="col-lg-12 col-12 col-md-12 col-xl-12 col-sm-12"
                  style="margin-bottom: 25px"
                >
                  <label for="example-text-input" class="form-control-label"
                    >Institución bancaria</label
                  >
                  <Skeletor
                    v-if="loading"
                    :shimmer="true"
                    height="60"
                    width="100%"
                    style="margin-bottom: 0px"
                  />
                  <v-select
                    v-else
                    placeholder="Seleccione un banco"
                    style="height: 30px"
                    label="Banco"
                    v-model="bank"
                    :disabled="isDisable"
                    :options="banksArray"
                    @option:selected="setSelected"
                  >
                    <template #search="{ attributes, events }">
                      <input
                        class="vs__search"
                        :required="!bankCode"
                        v-bind="attributes"
                        v-on="events"
                      />
                    </template>
                  </v-select>
                </div>

                <div class="col-md-6" style="margin-bottom: 10px">
                  <label for="example-text-input" class="form-control-label"
                    >Tipo de cuenta</label
                  >
                  <Skeletor
                    v-if="loading"
                    :shimmer="true"
                    height="40"
                    width="100%"
                  />
                  <select
                    v-else
                    v-model="type"
                    :disabled="isDisable"
                    class="form-select"
                    placeholder="Seleccione una opción"
                    required
                  >
                    <option selected value="">Seleccione una opción</option>
                    <option value="Ahorro">Ahorro</option>
                    <option value="Corriente">Corriente</option>
                  </select>
                </div>

                <div class="col-md-6" style="margin-bottom: 10px">
                  <label for="example-text-input" class="form-control-label"
                    >Nro. Cuenta bancaria(*)</label
                  >
                  <Skeletor
                    v-if="loading"
                    :shimmer="true"
                    height="40"
                    width="100%"
                  />
                  <input
                    v-else
                    class="form-control"
                    :disabled="isDisable"
                    type="text"
                    placeholder="Ingrese su número de cuenta"
                    v-model="numberAccount"
                    @input="validateNumberAccount"
                    required
                  />
                </div>

                <div
                  class="col-lg-12 col-12 col-md-12 col-xl-12 col-sm-12 d-flex justify-content-center mt-3"
                  align="center"
                >
                  <Skeletor
                    v-if="loading"
                    :shimmer="true"
                    pill
                    height="10"
                    width="70%"
                  />
                  <div v-else class="form-check" style="margin-bottom: 10px">
                    <input
                      class="form-check-input form-control"
                      type="checkbox"
                      required
                      v-model="terminos"
                      id="flexCheckDefault"
                      v-on:click="toggleBoton"
                    />
                    <label
                      class="form-check-label"
                      for="flexCheckDefault"
                      style="font-weight: bold; font-size: 11px"
                    >
                      Confirmo que la cuenta bancaria registrada es de mi
                      pertenencia
                    </label>
                  </div>
                </div>

                <div class="col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2"></div>

                <div
                  class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8"
                  align="center"
                  style="margin-top: 15px; margin-bottom: 15px"
                >
                  <button
                    id="btn_registrar"
                    type="submit"
                    class="btn btn-primary"
                    :disabled="botonHabilitado"
                    style="width: 100%"
                  >
                    Actualizar cuenta bancaria
                  </button>
                </div>

                <div class="col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2"></div>

                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  <div
                    class="alert alert-primary"
                    role="alert"
                    style="margin: 0px; font-size: 12px"
                  >
                    Los datos de la cuenta bancaria a registrarse deben
                    corresponder con la información personal registrada en su
                    perfil de XTRIM.<br />
                    No se aceptan cuentas bancarias de terceras personas, caso
                    contrario, sus solicitudes de retiro de dinero serán
                    rechazadas. <br />Esta cuenta bancaria será usada para el
                    depósito de sus comisiones.
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div class="col-12 col-sm-12 col-md-2 col-lg-3 col-xl-3"></div>
    </div>
  </div>
</template>

<script>
/*eslint-disable */

export default {
  name: "Banco",
  data() {
    let datos = JSON.parse(this.$store.state.user);

    return {
      isDisable: false,
      userId: datos.userId,
      tokenAuth: datos.tokenAuth,
      isMobile: this.$isMobile,
      loading: true,
      botonHabilitado: true,
      terminos: false,
      banksArray: [],
      bankCode: "",
      numberAccount: "",
      type: "",
      bank: "",
      datos: datos,
    };
  },
  activated() {
    if (this.isMobile) {
      this.$eventBus.emit("activeShowMenu");
    }
  },
  methods: {
    onSubmitCuenta(event) {
      event.preventDefault();
      this.isDisable = true;
      this.botonHabilitado = true;
      let ruta = `registerAccountBank`;
      let condicion =
        JSON.parse(localStorage.getItem("userData")).bankUser.length > 0;

      document.getElementById("btn_registrar").innerHTML =
        '<i class="fas fa-spinner fa-pulse"></i>  Actualizando';
      console.log("this.bank", this.bank);

      if (condicion) {
        ruta = `updateAccountBank`;
      }

      this.$https
        .post(`/bank/${ruta}`, {
          tokenAuth: this.tokenAuth,
          userId: this.userId,
          numberAccount: this.numberAccount,
          bankCode: this.bankCode,
          type: this.type,
          bank: this.bank,
        })
        .then((response) => {
          document.getElementById("btn_registrar").innerHTML =
            "Actualizar cuenta bancaria";
          this.isDisable = false;
          this.botonHabilitado = false;

          if (response.data.code == 200) {
            this.$toast.success(response.data.message, {
              position: "top-right",
              max: 10,
            });

            this.$store.state.user = localStorage.setItem(
              "userData",
              JSON.stringify({
                ...this.datos,
                bankUser: [
                  {
                    bank: this.bank,
                    type: this.type,
                    bankCode: this.bankCode,
                    numberAccount: this.numberAccount,
                  },
                ],
              })
            );

            this.terminos = false;
            this.botonHabilitado = true;
            this.datos;
          } else {
            this.$toast.error(response.data.message, {
              position: "top-right",
              max: 10,
            });

            if (response.data.code == 401) {
              localStorage.removeItem("userData");
              this.$store.state.user = "";

              // Redirect to login page
              this.$router.push({ name: "login" });
            }
          }
        })
        .catch((error) => {
          this.$toast.error("Ocurrió un error inesperado", {
            position: "top-right",
            max: 10,
          });
        });
    },

    setSelected(value) {
      this.bankCode = value.Codigo;
      this.bank = value.Banco;
    },

    toggleBoton() {
      this.botonHabilitado = !this.botonHabilitado;
    },
    isNumeric(input) {
      return /^\d+$/.test(input); // This regex checks if the input is all digits
    },
    validateNumberAccount() {
      if (!this.isNumeric(this.numberAccount)) {
        // Handle invalid input (e.g., show an error message)
      } else {
        // Handle valid input
      }
    },
    loadBanco() {
      this.loading = true;

      this.$https
        .post("/bank/loadBank", {
          tokenAuth: this.tokenAuth,
        })
        .then((response) => {
          console.log("response", response);
          if (response.data.code == 200) {
            this.banksArray = response.data.bancos;
            const bankUser = JSON.parse(this.$store.state.user).bankUser;

            if (bankUser.length > 0) {
              this.numberAccount = bankUser[0].numberAccount;
              this.type = bankUser[0].type;
              this.bankCode = bankUser[0].bankCode;
              this.bank = bankUser[0].bank;
            }

            this.loading = false;
            // localStorage.setItem(
            //   "userData",
            //   JSON.stringify({
            //     ...this.datos,
            //     bankUser: {
            //       numberAccount: this.numberAccount,
            //       type: this.type,
            //       bankCode: this.bankCode,
            //       bank: this.bank,
            //     },
            //   })
            // );
          } else {
            if (response.data.code == 401) {
              this.$toast.error(response.data.message, {
                position: "top-right",
                max: 10,
              });

              localStorage.removeItem("userData");
              this.$store.state.user = "";

              // Redirect to login page
              this.$router.push({ name: "login" });
            } else {
              this.loadBanco();
            }
          }
        })
        .catch((error) => {
          this.loadBanco();
        });
    },
  },
  mounted() {
    this.loadBanco();
  },
};
</script>

<style scoped>
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.no-spinner::-webkit-inner-spin-button,
.no-spinner::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.vs__search {
  height: 30px;
}
</style>
