<template>
  <div>
    <PWAPrompt v-if="shown" :install-event="installEvent" />

    <div class="container-fluid">
      <div
        v-if="isMobile"
        class="row vh-100"
        style="min-height: calc(var(--vh, 1vh) * 100); margin: 0px"
      >
        <div
          class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 d-flex d-sm-flex d-md-flex d-md-flex d-lg-flex d-xl-flex justify-content-center text-center align-items-center"
        >
          <div class="row align-items-center">
            <div class="col-12 col-md-12 col-lg-12">
              <span class="text-primary">
                <img
                  src="../../src/assets/img/Xtrim/xtrim_logo_morado.svg"
                  style="width: 300px"
                />
              </span>

              <p
                class="mb-0 text-center"
                style="font-size: 16px; margin-top: 10px"
              >
                Gana dinero por referir el internet de XTRIM
              </p>

              <formulario
                :is-login="isLogin"
                :promo-code="promoCode"
                :texto-button="textoButton"
                v-if="!registerForm"
                @toggle-form="toggleRegisterForm"
              />

              <reinicioContrasena v-else @toggle-form="toggleRegisterForm" />

              <hr class="horizontal dark" style="margin-top: 35px" />

              <a
                target="_blank"
                title="Facebook de XTrimPro"
                href="https://www.facebook.com/profile.php?id=100090370913056"
              >
                <i class="fab fa-facebook-f redes" aria-hidden="true"></i>
              </a>
              <a
                target="_blank"
                title="Instagram de XTrimPro"
                href="https://www.instagram.com/xtrimpro.ec/"
              >
                <i class="fab fa-instagram redes" aria-hidden="true"></i>
              </a>
              <a
                target="_blank"
                title="Email de XTrimPro"
                href="mailto:hola@xtrimproec.com?Subject=Tengo%20una%20pregunta"
              >
                <i class="fas fa-envelope redes" aria-hidden="true"></i>
              </a>
              <a
                target="_blank"
                title="Tiktok de XTrimPro"
                href="https://www.tiktok.com/@xtrimproec"
              >
                <svg
                  style="margin-bottom: 10px"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#7d0c7e"
                  widht="10"
                  height="22"
                  viewBox="0 0 448 512"
                >
                  <path
                    d="M448,209.91a210.06,210.06,0,0,1-122.77-39.25V349.38A162.55,162.55,0,1,1,185,188.31V278.2a74.62,74.62,0,1,0,52.23,71.18V0l88,0a121.18,121.18,0,0,0,1.86,22.17h0A122.18,122.18,0,0,0,381,102.39a121.43,121.43,0,0,0,67,20.14Z"
                  /></svg
              ></a>

              <p style="margin-top: 10px; margin-bottom: 0; font-size: 12px">
                <a
                  href="https://firebasestorage.googleapis.com/v0/b/xtrimpro-mlm.appspot.com/o/legalDocuments%2Fterminos_final.pdf?alt=media&token=e822415d-b913-4b78-977d-97dd918ed54b"
                  target="_blank"
                  >Términos y condiciones</a
                >
                -
                <a
                  href="https://firebasestorage.googleapis.com/v0/b/xtrimpro-mlm.appspot.com/o/legalDocuments%2Fpolitica-xtrim.pdf?alt=media&token=8bd4a1e6-a1e6-4903-a7d9-216420375b04"
                  target="_blank"
                  >Política de privacidad</a
                >
                -
                <a
                  href="https://firebasestorage.googleapis.com/v0/b/xtrimpro-mlm.appspot.com/o/legalDocuments%2Faviso_legal_xtrimpro.pdf?alt=media&token=ee320100-198f-4fef-9872-eef9d6bd5739"
                  target="_blank"
                  >Aviso legal</a
                >-
                <a
                  href="https://firebasestorage.googleapis.com/v0/b/xtrimpro-mlm.appspot.com/o/legalDocuments%2FDisclaimer-xtrim.pdf?alt=media&token=d313f2c4-7c0f-42ca-b6e2-fb0f7e1e2767"
                  target="_blank"
                  >Disclaimer</a
                >
              </p>

              <p style="margin-top: 0px; font-size: 12px; margin-bottom: 10px">
                Copyright &copy; {{ year }}
                <a href="https://xtrimproec.com" target="_blank">- XTrimPro</a>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div
        v-else
        class="row vh-100"
        style="min-height: calc(var(--vh, 1vh) * 100); margin: 0px"
      >
        <div
          style="padding: 0px; height: 100vh"
          class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 d-none d-sm-none d-md-none d-md-none d-lg-none d-xl-flex justify-content-center align-items-center"
        >
          <v-lazy-image
            :src="photo"
            style="
              object-fit: cover;
              object-position: top;
              height: 100%;
              width: 100%;
            "
          />
        </div>

        <div
          class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4 d-flex d-sm-flex d-md-flex d-md-flex d-lg-flex d-xl-flex justify-content-center text-center align-items-center"
        >
          <div class="row align-items-center">
            <div class="col-12 col-md-12 col-lg-12">
              <span class="text-primary">
                <img
                  src="../../src/assets/img/Xtrim/xtrim_logo_morado.svg"
                  style="width: 300px"
                />
              </span>

              <p
                class="mb-0 text-center"
                style="font-size: 16px; margin-top: 10px"
              >
                Gana dinero por referir el internet de XTRIM
              </p>

              <formulario
                :is-login="isLogin"
                :promo-code="promoCode"
                :texto-button="textoButton"
                v-if="!registerForm"
                @toggle-form="toggleRegisterForm"
              />

              <reinicioContrasena v-else @toggle-form="toggleRegisterForm" />

              <hr class="horizontal dark" style="margin-top: 35px" />

              <a
                target="_blank"
                title="Facebook de XTrimPro"
                href="https://www.facebook.com/profile.php?id=100090370913056"
              >
                <i class="fab fa-facebook-f redes" aria-hidden="true"></i>
              </a>
              <a
                target="_blank"
                title="Instagram de XTrimPro"
                href="https://www.instagram.com/xtrimpro.ec/"
              >
                <i class="fab fa-instagram redes" aria-hidden="true"></i>
              </a>
              <a
                target="_blank"
                title="Email de XTrimPro"
                href="mailto:hola@xtrimproec.com?Subject=Tengo%20una%20pregunta"
              >
                <i class="fas fa-envelope redes" aria-hidden="true"></i>
              </a>
              <a
                target="_blank"
                title="Tiktok de XTrimPro"
                href="https://www.tiktok.com/@xtrimproec"
              >
                <svg
                  style="margin-bottom: 10px"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#7d0c7e"
                  widht="10"
                  height="22"
                  viewBox="0 0 448 512"
                >
                  <path
                    d="M448,209.91a210.06,210.06,0,0,1-122.77-39.25V349.38A162.55,162.55,0,1,1,185,188.31V278.2a74.62,74.62,0,1,0,52.23,71.18V0l88,0a121.18,121.18,0,0,0,1.86,22.17h0A122.18,122.18,0,0,0,381,102.39a121.43,121.43,0,0,0,67,20.14Z"
                  /></svg
              ></a>

              <p style="margin-top: 10px; margin-bottom: 0; font-size: 12px">
                <a
                  href="https://firebasestorage.googleapis.com/v0/b/xtrimpro-mlm.appspot.com/o/legalDocuments%2Fterminos_final.pdf?alt=media&token=e822415d-b913-4b78-977d-97dd918ed54b"
                  target="_blank"
                  >Términos y condiciones</a
                >
                -
                <a
                  href="https://firebasestorage.googleapis.com/v0/b/xtrimpro-mlm.appspot.com/o/legalDocuments%2Fpolitica-xtrim.pdf?alt=media&token=8bd4a1e6-a1e6-4903-a7d9-216420375b04"
                  target="_blank"
                  >Política de privacidad</a
                >
                -
                <a
                  href="https://firebasestorage.googleapis.com/v0/b/xtrimpro-mlm.appspot.com/o/legalDocuments%2Faviso_legal_xtrimpro.pdf?alt=media&token=ee320100-198f-4fef-9872-eef9d6bd5739"
                  target="_blank"
                  >Aviso legal</a
                >-
                <a
                  href="https://firebasestorage.googleapis.com/v0/b/xtrimpro-mlm.appspot.com/o/legalDocuments%2FDisclaimer-xtrim.pdf?alt=media&token=d313f2c4-7c0f-42ca-b6e2-fb0f7e1e2767"
                  target="_blank"
                  >Disclaimer</a
                >
              </p>

              <p style="margin-top: 0px; font-size: 12px; margin-bottom: 10px">
                Copyright &copy; {{ year }}
                <a href="https://xtrimproec.com" target="_blank">- XTrimPro</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable */

import Formulario from "./Formulario.vue";
import PWAPrompt from "./PWAPrompt.vue";
import reinicioContrasena from "./ReinicioContrasena.vue";

export default {
  name: "signin",
  components: {
    Formulario,
    reinicioContrasena,
    PWAPrompt,
  },
  data() {
    return {
      isLogin: true,
      textoButton: "Inicia con",
      promoCode: "",
      year: new Date().getFullYear(),
      isMobile: this.$isMobile,
      photo: "/img/xtrimpro-mlm.jpeg",
      shown: false,
      installEvent: null,
      registerForm: false,
    };
  },
  beforeMount() {
    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
      this.installEvent = e;
      this.shown = true;
    });
  },
  mounted() {
    document.body.classList.remove("dark-version");

    this.$eventBus.on("ocultarPWA", () => {
      this.shown = false;
    });

    document.title = "XTrimPro - Inicio de sesión";
  },
  methods: {
    toggleRegisterForm() {
      this.registerForm = !this.registerForm;
    },
  },
  created() {},
  beforeUnmount() {},
};
</script>

<style scoped>
.container-fluid {
  padding: 0px;
  margin: 0px;
}
</style>
