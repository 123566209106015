<template>
  <div
    class="card referCard"
    :class="{ card2: isMobile, margin_card: !isMobile }"
  >
    <!-- style="background: #5e005f" -->
    <div class="card-body">
      <div class="row flex-column justify-content-around">
        <!-- <div class="col-lg-12 col-md-12 col-12 col-sm-12 col-xl-12"> -->
        <h6
          style="
            font-size: 14px;
            color: white;
            font-weight: bold;
            text-align: center;
            margin-bottom: 20px;
            line-height: 15px;
          "
        >
          ¡Invita a otras personas a tu Equipo!
        </h6>
        <!-- </div> -->

        <!-- <div class="col-lg-12 col-md-12 col-12 col-sm-12 col-xl-12"> -->
        <div class="input-group justify-content-center">
          <input
            style="background-color: #e9ecef; color: black"
            type="text"
            required
            :readonly="true"
            v-model="link1"
            class="form-control col-lg-6 text-center"
            id="link1"
            ref="link1"
            aria-describedby="button-addon2"
          />
          <button
            class="btn-outline-secondary btn2"
            style="background: #e9ecef; color: #000"
            @click="copyInputValue('link1')"
          >
            <i
              class="fa-regular fa-clone"
              aria-hidden="true"
              style="margin-right: 5px"
            ></i>
          </button>
        </div>

        <hr class="horizontal dark" style="margin-bottom: 10px" />

        <a href="#" target="_blank"
          ><p class="a_link2" style="color: white">Ver tutorial de uso</p></a
        >
        <!-- </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: ["promoCode", "isMobile"],
  data() {
    return {
      link1: "",
    };
  },
  computed: {},
  watch: {},
  mounted() {
    this.link1 = window.location.host + "/invitacion/" + this.promoCode;
  },
  methods: {
    copyInputValue(model) {
      const inputElement = document.getElementById(model);
      inputElement.select();
      document.execCommand("copy");
      inputElement.setSelectionRange(0, 0);
      this.$toast.success("Link Copiado exitosamente", {
        position: "top-right",
        max: 10,
      });
    },
  },
};
</script>
