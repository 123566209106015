<template>
  <div
    class="planCard planCard-body"
    style="padding: 0; width: 160px; text-align: center; padding: 1rem"
    :class="isSelected ? 'selectedPlan' : ''"
    @click="selectPlan"
  >
    <h5 class="planCard-title" style="color: #7d0c7e">Plan</h5>
    <h5 class="planCard-title" style="color: #7d0c7e">
      {{ selectedPlan.name }}
    </h5>
    <p class="planCard-text" style="font-size: 16px">
      {{ selectedPlan.description }}
    </p>
    <!-- <p class="planCard-text">Bono: {{ selectedPlan.bono }}</p>
      <p class="planCard-text">Plan ID: {{ selectedPlan.planId }}</p> -->
    <p
      class="planCard-text price-text"
      :class="isSelected ? 'selectedPrice' : ''"
      style="font-size: 18px"
    >
      ${{ selectedPlan.price.toFixed(2) }}
    </p>
  </div>
</template>

<script>
export default {
  name: "PlanCard",
  props: {
    plan: {
      type: String,
      required: true,
    },
    selectedPlan: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    console.log("plan", this.plan);
  },
  computed: {
    isSelected() {
      return this.plan === this.selectedPlan.planId;
    },
  },
  methods: {
    selectPlan() {
      this.$emit("plan-selected", this.selectedPlan.planId);
    },
  },
};
</script>

<style scoped></style>
