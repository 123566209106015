<template>
  <div class="card" style="margin-bottom: 10px">
    <div class="card-body">
      <div class="row">
        <div class="col-10 col-sm-10 col-md-11 col-lg-11 col-xl-11">
          <p style="margin: 10px; font-weight: bold">
            <i style="margin-right: 10px" class="fas fa-list"></i> LISTADO DE
            TRANSACCIONES
          </p>
        </div>

        <div
          class="col-2 col-sm-2 col-md-1 col-lg-1 col-xl-1"
          align="center"
          style="display: flex"
        >
          <Skeletor
            v-if="loading"
            :shimmer="true"
            height="20"
            width="100%"
            style="margin: auto"
          />
          <div v-else @click="refresh">
            <i
              style="cursor: pointer; margin: auto"
              class="fas fa-sync text-sm opacity-10"
            ></i>
          </div>
        </div>
      </div>

      <div
        v-if="loading"
        class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
        style="padding: 0px"
      >
        <div class="row">
          <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
            <Skeletor :shimmer="true" height="40" width="50%" />
          </div>

          <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
            <Skeletor
              :shimmer="true"
              height="10"
              pill
              width="30%"
              style="margin-bottom: 10px"
            />
            <Skeletor :shimmer="true" height="40" width="100%" />
          </div>
          <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <table class="table tablesorter">
              <thead class="thead-light">
                <tr>
                  <th>
                    <Skeletor :shimmer="true" height="10" width="100%" />
                  </th>
                  <th>
                    <Skeletor :shimmer="true" height="10" width="100%" />
                  </th>
                  <th>
                    <Skeletor :shimmer="true" height="10" width="100%" />
                  </th>
                  <th>
                    <Skeletor :shimmer="true" height="10" width="100%" />
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <Skeletor :shimmer="true" height="20" width="100%" />
                  </td>
                  <td>
                    <Skeletor :shimmer="true" height="20" width="100%" />
                  </td>
                  <td>
                    <Skeletor :shimmer="true" height="20" width="100%" />
                  </td>
                  <td>
                    <Skeletor :shimmer="true" height="20" width="100%" />
                  </td>
                </tr>
                <tr>
                  <td>
                    <Skeletor :shimmer="true" height="20" width="100%" />
                  </td>
                  <td>
                    <Skeletor :shimmer="true" height="20" width="100%" />
                  </td>
                  <td>
                    <Skeletor :shimmer="true" height="20" width="100%" />
                  </td>
                  <td>
                    <Skeletor :shimmer="true" height="20" width="100%" />
                  </td>
                </tr>
                <tr>
                  <td>
                    <Skeletor :shimmer="true" height="20" width="100%" />
                  </td>
                  <td>
                    <Skeletor :shimmer="true" height="20" width="100%" />
                  </td>
                  <td>
                    <Skeletor :shimmer="true" height="20" width="100%" />
                  </td>
                  <td>
                    <Skeletor :shimmer="true" height="20" width="100%" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="col-6 col-sm-6 col-md-8 col-lg-8 col-xl-8">
            <Skeletor :shimmer="true" height="10" pill width="80%" />
          </div>

          <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4">
            <div class="row">
              <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                <Skeletor :shimmer="true" height="10" width="100%" />
              </div>

              <div
                class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4"
                align="center"
              >
                <Skeletor :shimmer="true" circle size="30" />
              </div>

              <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                <Skeletor :shimmer="true" height="10" width="100%" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <DataTable
        v-else
        :columns="columns"
        :options="options"
        :data="transactions"
        class="table tablesorter"
        width="100%"
        id="myTable"
      >
        <thead>
          <tr>
            <th>#</th>
            <th>Fecha/Hora</th>
            <th data-priority="12000">Nombre</th>
            <th data-priority="12000">Observaciones</th>
            <th data-priority="1">Valor</th>
            <th data-priority="12000">Motivo</th>
            <th></th>
          </tr>
        </thead>
      </DataTable>
    </div>
  </div>
  <div class="card" v-if="transactionCommissions" style="margin-bottom: 10px">
    <div class="card-body">
      <div class="col-10 col-sm-10 col-md-11 col-lg-11 col-xl-11">
        <p style="margin: 10px; font-weight: bold">
          <i style="margin-right: 10px" class="fas fa-list"></i> COMISIONES
        </p>
      </div>
      <DataTable
        v-if="transactionCommissions"
        :columns="columnsCommissions"
        :options="optionsCommissions"
        :data="commissions"
        class="table tablesorter"
        width="100%"
        id="myTable"
      >
        <thead>
          <tr>
            <th>#</th>
            <th>Promotor</th>
            <th>Nivel</th>
            <th># Ventas</th>
            <th>Valor</th>
          </tr>
        </thead>
      </DataTable>
    </div>
  </div>
</template>
<script>
/* eslint-disable */

import DataTable from "datatables.net-vue3";
import DataTablesCore from "datatables.net-bs5";
import "datatables.net-responsive";
import $ from "jquery";

DataTable.use(DataTablesCore);

export default {
  components: {
    DataTable,
  },

  data() {
    const datos_json = JSON.parse(this.$store.state.user);
    return {
      tokenAuth: datos_json.tokenAuth,
      loading: true,
      isMobile: this.$isMobile,
      transactions: [],
      userId: datos_json.userId,
      mapComisiones: {},
      transactionCommissions: "",
      columns: [
        { data: "transactionCode" },
        { data: "dateRegister" },
        { data: "displayName" },
        { data: "observations" },
        { data: "amount" },
        { data: "type" },
        {
          // This will be the 'details control' column
          orderable: false,
          searchable: false,
          data: null,
          render: function (data) {
            return `<div class="details btn btn-primary" data-transactionId="${
              data.transactionId
            }" style="white-space: nowrap; display: flex; align-items: center; gap: 12px;font-size: 12px; padding: 5px 10px; justify-content: center; ${
              data.type === "Retiro" ? "display: none" : "display: flex"
            }">Ver Detalle</div>`;
          },
          width: "auto",
        },
      ],
      options: {
        responsive: true,
        ordering: false,
        select: true,

        columnDefs: [
          { responsivePriority: 1, targets: 0 },
          { responsivePriority: 2, targets: -1 },
        ],
        lengthMenu: [
          [5, 15, 25, 35, 50, -1],
          [5, 15, 25, 35, 50, "Todos"],
        ],
        pageLength: 5,
        language: {
          search: "Buscar:",
          sInfo:
            "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
          sInfoEmpty:
            "Mostrando registros del 0 al 0 de un total de 0 registros",
          searchPlaceholder: "Ingrese algún dato",
          zeroRecords: "Lo sentimos, no se encontraron resultados",
          processing: "Buscando. Espere por favor...",
          sLengthMenu: "Mostrar _MENU_ registros",
          oPaginate: {
            sFirst: "Primero",
            sLast: "Último",
            sNext: "Siguiente",
            sPrevious: "Anterior",
          },
        },
      },
      columnsCommissions: [
        { data: "commissionCode", width: "5%" },
        { data: "sellerName", width: "65%" },
        { data: "level", width: "10" },
        { data: "salesNumber", width: "10%" },
        { data: "amount", width: "10%" },
      ],
      optionsCommissions: {
        responsive: true,
        ordering: true,
        select: true,
        columnDefs: [
          { responsivePriority: 1, targets: 0 },
          { responsivePriority: 2, targets: -1 },
          { targets: [2], orderable: true }, // Here, 2 represents the index of the "level" column
        ],
        lengthMenu: [
          [15, 25, 35, 50, 75, -1],
          [15, 25, 35, 50, 75, "Todos"],
        ],
        pageLength: 15,
        language: {
          search: "Buscar:",
          sInfo:
            "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
          sInfoEmpty:
            "Mostrando registros del 0 al 0 de un total de 0 registros",
          searchPlaceholder: "Ingrese algún dato",
          zeroRecords: "Lo sentimos, no se encontraron resultados",
          processing: "Buscando. Espere por favor...",
          sLengthMenu: "Mostrar _MENU_ registros",
          oPaginate: {
            sFirst: "Primero",
            sLast: "Último",
            sNext: "Siguiente",
            sPrevious: "Anterior",
          },
        },
      },
    };
  },
  activated() {
    if (this.isMobile) {
      this.$eventBus.emit("activeShowMenu");
    }
  },
  created() {
    // Bind the click event handler here
    $(document).on("click", ".details", (evt) => {
      const transactionId = $(evt.target).data("transactionid");
      this.transactionCommissions = transactionId;
    });
  },
  computed: {
    commissions() {
      return this.mapComisiones[this.transactionCommissions];
    },
  },
  methods: {
    formatUnixTimestamp(timestamp) {
      const date = new Date(timestamp * 1000); // Convert seconds to milliseconds
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so add 1
      const year = date.getFullYear();
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");

      return `${year}/${month}/${day}, ${hours}:${minutes}`;
    },
    loadTransactions() {
      const commissionsArray = [22, 3, 4, 2, 1.5];

      this.$https
        .post("/team/getConsulComisiones", {
          userId: this.userId,
          tokenAuth: this.tokenAuth,
        })
        .then((response) => {
          if (response.data.code == 200) {
            const userTransactions = response.data.transacciones;

            if (userTransactions) {
              //   this.transactions = userData;
              const fullData = userTransactions
                .map((transaction, index) => ({
                  ...transaction,
                  amount: `$${parseFloat(transaction.amount).toFixed(2)}`,
                  type: transaction.type === "pago" ? "Acreditación" : "Retiro",
                  transactionCode: index,
                  dateRegister: this.formatUnixTimestamp(
                    transaction.createdAt._seconds
                  ),
                }))
                .sort((a, b) => b.createdAt._seconds - a.createdAt._seconds);
              this.transactions = fullData;
              const commissionsObject = response.data.comisiones;

              // Create a new object to store the modified values
              const modifiedCommissionsObject = {};

              // Iterate over the keys of the original object
              Object.keys(commissionsObject).forEach((key) => {
                const commissions = commissionsObject[key];

                const modifiedCommissions = commissions.map(
                  (commission, index) => ({
                    ...commission,
                    amount: `$${parseFloat(commission.amount).toFixed(2)}`,
                    commissionCode: index,
                    salesNumber: commission.salesAmount
                      ? commission.salesAmount
                      : commission.amount / commissionsArray[commission.level],
                  })
                );

                // Add the modified array back to the new object
                modifiedCommissionsObject[key] = modifiedCommissions;
              });
              this.mapComisiones = modifiedCommissionsObject;
            } else {
              this.$toast.error("No posees transacciones", {
                position: "top-right",
                max: 10,
              });
            }
          }
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loadTransactions();
        });
    },

    refresh() {
      this.loading = true;
      this.loadTransactions();
    },
  },
  mounted() {
    this.loadTransactions();
  },
};
</script>

<style lang="scss">
@import "datatables.net-dt";
@import "datatables.net-responsive-dt";

table.dataTable tbody td {
  vertical-align: middle;
}

// .dtr-control::before {
//   padding: 0 5px !important;
// }
.dtr-control {
  position: relative !important;
  text-align: center !important;
  vertical-align: middle !important;
}

.dtr-control::before {
  position: absolute !important;
  top: 50% !important;
  left: 5% !important;
  transform: translate(-50%, -50%) !important;
}
</style>
