/* eslint-disable */
import { createRouter, createWebHistory } from "vue-router";
import Dashboard from "../views/Dashboard/Dashboard.vue";
import Signup from "../views/Signup.vue";
import Signin from "../views/Signin.vue";
import perfil from "../views/Perfil.vue";
import editarPerfil from "../views/Dashboard/EditarPerfil.vue";
import NotFound from "../views/404.vue";
import Venta from "../views/Venta.vue";
import Invitacion from "../views/Invitacion.vue";
import Landing from "../views/Landing.vue";
import DatosBancarios from "../views/DatosBancarios.vue";
import Terminos from "../views/Terminos.vue";
import Descripcion from "../views/Descripcion.vue";
import store from "@/store/index";
import Referidos from "../views/Referidos/Index.vue";
import Mas from "../views/Mas.vue";
import Password from "../views/Password.vue";
import transacciones from "../views/Dashboard/Transacciones.vue";
import detalleVentas from "../views/Dashboard/DetalleVentas.vue";
import Administracion from "../views/Administracion/Index.vue";
import estadisticas from "../views/Dashboard/Estadisticas.vue";
import detalleCredencial from "../views/Dashboard/DetalleCredencial.vue";
import recursos from "../views/Dashboard/Recursos.vue";
import Patrocinador from "../views/Dashboard/Patrocinador.vue";

const routes = [
  {
    path: "/",
    name: "landing",
    component: Signin,
    meta: { layout: "full" },
  },
  {
    path: "/invitacion/:promoCode?",
    name: "Invitacion",
    component: Invitacion,
    meta: { layout: "full" },
  },
  {
    path: "/dashboard/referidos",
    name: "referidos",
    component: Referidos,
    meta: {
      requiresAuth: true,
      layout: "horizontal",
      breadCrumb: "Mi Equipo XTRIMPRO",
    },
  },
  {
    path: "/dashboard/patrocinador",
    name: "patrocinador",
    component: Patrocinador,
    meta: {
      requiresAuth: true,
      layout: "horizontal",
      breadCrumb: "Patrocinador",
    },
  },
  {
    path: "/dashboard/banco",
    name: "banco",
    component: DatosBancarios,
    meta: {
      requiresAuth: true,
      layout: "horizontal",
      breadCrumb: "Cuenta bancaria",
    },
  },
  {
    path: "/dashboard/mas",
    name: "mas",
    component: Mas,
    meta: {
      requiresAuth: true,
      layout: "horizontal",
      breadCrumb: "Otras opciones",
    },
  },
  {
    path: "/dashboard/terminos",
    name: "terminos",
    component: Terminos,
    meta: {
      requiresAuth: true,
      layout: "horizontal",
      breadCrumb: "Términos y condiciones",
    },
  },
  {
    path: "/dashboard/descripcion",
    name: "descripcion",
    component: Descripcion,
    meta: {
      requiresAuth: true,
      layout: "horizontal",
      breadCrumb: "Descripción y Términos de XTRIMPRO",
    },
  },
  {
    path: "/venta/:promoCode?",
    name: "Venta",
    component: Venta,
    meta: { layout: "full" },
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    meta: { requiresAuth: true, layout: "horizontal", breadCrumb: "Inicio" },
  },
  {
    path: "/administracion",
    name: "Administracion",
    component: Administracion,
    meta: {
      requiresAuth: true,
      layout: "horizontal",
      breadCrumb: "Administración",
    },
  },
  {
    path: "/login",
    name: "Signin",
    component: Signin,
    meta: { layout: "full" },
  },
  {
    path: "/signup",
    name: "Signup",
    component: Signup,
    meta: { layout: "full" },
  },
  {
    path: "/dashboard/editarPerfil",
    name: "editarPerfil",
    component: perfil,
    meta: { requiresAuth: true, layout: "full" },
  },
  {
    path: "/dashboard/perfil",
    name: "Perfil",
    component: editarPerfil,
    meta: {
      requiresAuth: true,
      layout: "horizontal",
      breadCrumb: "Editar perfil",
    },
  },
  {
    path: "/dashboard/password",
    name: "password",
    component: Password,
    meta: { requiresAuth: true, layout: "full" },
  },
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: NotFound,
    meta: { layout: "full" },
  },
  {
    path: "/dashboard/transacciones",
    name: "transacciones",
    component: transacciones,
    meta: {
      requiresAuth: true,
      layout: "horizontal",
      breadCrumb: "Mis transacciones",
    },
  },
  {
    path: "/dashboard/ventas",
    name: "ventas",
    component: detalleVentas,
    meta: {
      requiresAuth: true,
      layout: "horizontal",
      breadCrumb: "Mis Ventas Directas",
    },
  },
  {
    path: "/dashboard/credencial",
    name: "credencial",
    component: detalleCredencial,
    meta: {
      requiresAuth: true,
      layout: "horizontal",
      breadCrumb: "Credencial",
    },
  },
  {
    path: "/dashboard/recursos",
    name: "recursos",
    component: recursos,
    meta: {
      requiresAuth: true,
      layout: "horizontal",
      breadCrumb: "Recursos",
    },
  },
  {
    path: "/dashboard/estadisticas",
    name: "estadisticas",
    component: estadisticas,
    meta: {
      requiresAuth: true,
      layout: "horizontal",
      breadCrumb: "Estadisticas",
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((route) => route.meta.requiresAuth)) {
    const userData = localStorage.getItem("userData");
    const user = userData;

    if (!user) {
      next("/login");
    } else {
      // Verificar si la ruta requiere un rol específico
      const requiredRole = to.meta.requiredRole;
      if (requiredRole) {
        const session = localStorage.getItem("session");
        const decrypt = new JSEncrypt();
        decrypt.setPrivateKey(keys.publicKeyBackoffice);
        const userRole = decrypt.decrypt(session);

        if (Array.isArray(requiredRole) && requiredRole.includes(userRole)) {
          next();
        } else {
          next("/dashboard");
        }
      } else {
        next();
      }
    }
  } else {
    next();
  }
});

router.afterEach(() => {
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router;
